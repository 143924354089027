import React from 'react';
import { useDispatch } from 'react-redux';
import { usePageView } from 'utils/hooks';

import { useRouter } from '../../../apis/history';
import { updateQuizAnswers } from '../../../state/user/effects';

import { Header } from './sections/Header';

const Landing: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const { goToQuiz } = useRouter();

  usePageView();

  const handleGenderSelection = (gender: string) => {
    dispatch(updateQuizAnswers({ gender }));

    goToQuiz();
  };

  return (
    <>
      <Header onSelect={handleGenderSelection} />
    </>
  );
});

Landing.displayName = 'Landing';

export default Landing;
